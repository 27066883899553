import axios from '@/libs/axios'

class DataService {
    getAll(page) {
        return axios.get("/machines?page="+page);
    }
    getBrands() {
        return axios.get('/brands')
    }
    get(id) {
        return axios.get(`/machines/${id}`);
    }
    create(data) {
        return axios.post("/machines", data);
    }
    update(id, data) {
        return axios.post(`/machines/${id}`, data);
    }
    delete(id) {
        return axios.delete(`/machines/${id}`);
    }
    mahineSize() {
        return axios.get("/machineSize");
    }
    changeStatus(id) {
        return axios.get(`/changeMachineStatus/${id}`)
    }
}
export default new DataService();
<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{headerType}} {{ pageTitle }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="brnadForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="initValues"
        >

          <!-- English Name -->
          <validation-provider
            #default="validationContext"
            name="English Name"
            rules="required"
          >
            <b-form-group
              label="English Name"
              label-for="english-name"
            >
              <b-form-input
                id="english-name"
                v-model="name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter English name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.name">{{ allerrors.name[0] }}</span>
            </b-form-group>
          </validation-provider>

          <!-- Arabic Name -->
          <validation-provider
            #default="validationContext"
            name="Arabic Name"
            rules="required"
          >
            <b-form-group
              label="Arabic Name"
              label-for="arabic_name"
            >
              <b-form-input
                id="arabic_name"
                v-model="name_ar"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter arabic name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.name_ar">{{ allerrors.name_ar[0] }}</span>
            </b-form-group>
          </validation-provider>


          <!--  Machine Type -->
          <validation-provider
            #default="validationContext"
            name="Select machine type"
            rules="required"
          >
            <b-form-group
              label="Select Machine Type"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
               <v-select
                  id="type"
                  v-model="machine_type_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="description"
                  :options="types"
                  :reduce="types => types.id"
                  @input="getSpecificBrands"
                />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.machine_type_id">{{ allerrors.machine_type_id[0] }}</span>
            </b-form-group>
          </validation-provider>


          <!-- select brand -id -->
          <validation-provider
            #default="validationContext"
            name="Select type"
            rules=""
          >
            <b-form-group
              label="Select Brand"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
                
               <v-select
                  id="type"
                  v-model="brand_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="specificBrands"
                  :reduce="types => types.id"
                  select
                >
                <option value="0">amro</option>
                </v-select>

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.type">{{ allerrors.type[0] }}</span>
            </b-form-group>
          </validation-provider>


          <!-- number of liter for generater machine type -->
          <validation-provider
            #default="validationContext"
            name="numberliter"
            rules="required"
            v-if="machine_type_id == 2"
          >
            <b-form-group
              label="Liter number"
              label-for="numberLiter"
            >
              <b-form-input
                id="numberLiter"
                v-model="number_liter"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Liter Number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.number_liter">{{ allerrors.number_liter[0] }}</span>
            </b-form-group>
          </validation-provider>

          <div >
            <b-media class="mb-2">
              <template #aside >
                <b-avatar
                  v-if="imageUrl"
                  ref="previewEl"
                  :src="imageUrl"
                  :text="avatarText(name)"
                  size="90px"
                  rounded
                />
              </template>
              
              
            </b-media>
            <div class="d-flex flex-wrap mb-2">
              <b-button
                variant="primary"
                @click="$refs.imageRef.click()"
              >
                <input
                  ref="imageRef"
                  type="file"
                  class="d-none"
                  @change="onImageChange"
                >
                <span class="d-none d-sm-inline">Upload image</span>
                <feather-icon
                  icon="EditIcon"
                  class="d-inline d-sm-none"
                />
              </b-button>
              <span class="text-danger text-sm" v-if="allerrors.machines_image">{{ allerrors.machines_image[0] }}</span>
            </div>
            <!-- <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>

          </validation-provider> -->
          </div>



          <b-row
            v-if="machine_type_id == 1"
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"   
            >
          <!-- Value -->
          <b-col lg="10" md="10">
          <!--  value -->
            <validation-provider
              #default="validationContext"
              name="Value"
              rules="required" 
              >
              <b-form-group
              label-for="value"
              >
                  <v-select
                      id="value"
                      v-model="item.machine_size_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="size"
                      :options="machineSizes"
                      :reduce="types => types.id"
                      :state="getValidationState(validationContext)"
                      select
                      placeholder="Machine size"
                  />

              <b-form-invalid-feedback class="d-block" v-if="validationContext.errors[0]">
                  {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.machine_size_id">{{ allerrors.machine_size_id[0] }}</span>
              </b-form-group>
          </validation-provider>
          </b-col>


          <!-- oil capcity -->
          <!-- <b-col md="4" class="mb-1">
              <validation-provider
                  #default="validationContext"
                  name="oil-capcity"
                  rules=""
              >
                  <b-form-group
                  label-for="oil-capcity"
                  >
                     
                      <b-form-input
                          id="oil-capcity"
                          v-model="item.number_liter"
                          autofocus
                          trim
                          type="text"
                          placeholder="Oil volume"
                          :state="getValidationState(validationContext)"
                        />
                  <b-form-invalid-feedback class="d-block" v-if="validationContext.errors[0]">
                      {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <span class="text-danger text-sm" v-if="allerrors.price">{{ allerrors.price[0] }}</span>
                  </b-form-group>
              </validation-provider>
          </b-col> -->
          
          
          <!-- Remove Button -->
          <b-col
            lg="1"
            md="3"
            style="margin-top: 0rem !important; margin-bottom: 0rem; height: 45px"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              style="padding: 10px;"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
            </b-button>
          </b-col>
          
        </b-row>

        <b-button
         v-if="machine_type_id == 1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          type="submit"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Add New Value</span>
        </b-button>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!loading"
            >
              {{ headerType }} 
            </b-button>
            <!-- spinner -->
            <b-button
              v-if="loading"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner small />
              Loading...
            </b-button>
            <!-- end spinner -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormFile, BCardText, BMedia, BAvatar, BSpinner, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import DataService from './data/services'
export default {
  
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    vSelect,
    BSpinner,
    BRow, 
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    useInputImageRenderer,
    avatarText,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataSilder: {
      type: Object,
      default: () => {},
    },
    pageTitle: {
      type: String,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      name:'',
      name_ar:'',
      type:'car',
      brand_id: 0,
      machines_image:'',
      machineSizes: [],
      machine_type_id: '1',
      brands: [],
      specificBrands: [],
      number_liter: 0,
      imageUrl: '',
      id: '',
      loading: false,
      headerType: 'Add',
      allerrors: [],
      checkMachineType: '',
    }
  },
  watch: {
    isAddNewUserSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.dataSilder).length === 0) {
        this.headerType = 'Add'
        this.initValues()
        this.getSpecificBrands(this.machine_type_id)
      }else {    
        this.getSpecificBrands(this.dataSilder.machine_type_id)
        this.id       = this.dataSilder.id
        this.name     = this.dataSilder.name
        this.name_ar  = this.dataSilder.name_ar
        this.machines_image = this.dataSilder.machines_image
        this.brand_id     = this.dataSilder.brand_id
        this.imageUrl = this.dataSilder.brands_image
        this.machines_image = this.dataSilder.machines_image
        this.number_liter = this.dataSilder.number_liter
        this.machine_type_id = this.dataSilder.machine_type_id
        this.headerType = 'Edit'
        this.$store.state.admin.machines = []
        
        Object.entries(this.dataSilder.machine_size).forEach(([key, value]) => {
          this.$store.commit('admin/AddMachines', value )
        });
       

        // this.initValues()
      }
    },
    // machine_type_id(val) {
    //   this.checkMachineType = (val == 1) ? 'car' : 'gen' ;
    // }
  },
  methods: {
    initValues() {
      if(this.dataSilder.id) return
        this.id = null
        this.name = null
        this.name_ar = null
        this.machines_image = null
        this.brand_id = null
        this.imageUrl = null
        this.number_liter = null
        this.machine_type_id
        this.$store.state.admin.machines = [{  machine_size_id: '', number_liter: ''}]
        this.allerrors = {}
    },

    onImageChange(e) {
      const file = e.target.files[0];
      this.machines_image = file
      this.imageUrl = URL.createObjectURL(file);
    },
    onSubmit(){
      if(!this.id){
        this.sendData()
      }else{
        this.editData();
      }
    },
    prepareData(){
      const data = new FormData();
      data.append('name', this.name);
      data.append('name_ar', this.name_ar);
      data.append('brand_id', this.brand_id);
      data.append('machines_image', this.machines_image);
      data.append('machine_type_id', this.machine_type_id);
      data.append('number_liter',this.number_liter);
      (this.id)? data.append('_method', 'put') : ''


      Object.entries(this.$store.state.admin.machines).forEach(([key, value]) => {
        data.append(`machine_size_oil[${key}][number_liter]`, value.number_liter)
        data.append(`machine_size_oil[${key}][machine_size_id]`, value.machine_size_id)
      });



      return data;
    },
    getBrands(){
        DataService.getBrands()
        .then(response => {
          this.brands = response.data.data         
        })
    },
    sendData(){
      this.loading = true;
        DataService.create(this.prepareData())
        .then(response => {
          this.loading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            this.$emit('update:is-add-new-user-sidebar-active', false)
            this.$parent.refreshData(response.data.data);            
          }
          else if(responseCode === 101){
            console.log('error');
            this.allerrors = response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
          else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
    editData(){
      this.loading = true;
        DataService.update(this.id, this.prepareData())
        .then(response => {
          this.loading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            this.$emit('update:is-add-new-user-sidebar-active', false)
            this.$emit('refreshData',response.data.data)          
          }
          else if(responseCode === 101){
            console.log('error');
            this.allerrors = response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
          else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
    getMachineSizes(){
        DataService.mahineSize()
        .then(response => {
          this.machineSizes = response.data.data
        })
    },
    repeateAgain() {
      this.$store.commit('admin/AddMachines', {  machine_size_id: '', number_liter: ''} )
    },
    removeItem(index) {
      this.$store.commit('admin/RemoveMachines', index)
    },
    getSpecificBrands(val){
      console.log(val);
      this.specificBrands = []
      let brands = this.brands
      Object.entries(brands).forEach(([key, value]) => {
        if(val == value.machine_type_id){
          this.specificBrands.push(value)
        }
      });
    }

  },
  mounted() {
    this.getBrands()
    this.getMachineSizes()
  },
  computed: {
    items () {
      return this.$store.getters['admin/GetMachines']
    },
    types (){
      return this.$store.getters['admin/GetMachineTypes'] 
    },
  },
  setup(props, { emit }) {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      console.log('amro');
      console.log(base64);
      console.log(props);
      props.categories_image_img = base64
    })


    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      // userData,
      // onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
